body {
    font-family: 'Poppins', sans-serif;
}

.landing{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    width:100vw;
}

.leftSide{
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color:black;
    padding-left:15vw;
    box-sizing: border-box;
}

.rightSide{
    width:50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left:20px;
    box-sizing: border-box;
}

.heading{
    font-size:42px;
    font-weight: 700;
    line-height:60px;
    margin-top:20px;
    color:rgb(0, 0, 47)
}

.explainer{
    font-size:14px;
    margin-top:20px;
    font-weight:500;
    color:gray;
}

.sponsor{
    padding:10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background:white;
    border:2px solid rgb(212, 212, 212);
    color:rgb(101, 101, 101);
    border-radius:10px;
    gap:10px;
}

.sponsorLogo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.broughtBy{
    font-size:12px;
    font-weight:600;
}

.upscale{
    font-size:16px;
    font-weight:700;
}

.loginButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:white;
    padding:18px 25px 18px 25px;
    font-weight:600;
    background: linear-gradient(180deg, rgba(10,72,157,1) 0%, rgba(32,148,249,1) 100%);
    border: none;
    border-radius:50px;
    box-shadow: rgba(10,72,157,1) 0px 4px 12px;
    cursor:pointer;
    transition:0.2s;
    margin-top:50px;
    background-size: 130% 130%;
    font-size: 16px;
}

.navbarLoginButton{
    margin:0px;
    padding:10px 20px 10px 20px;
    border-radius: 50px;
    font-size: 13px;
    box-shadow:none;
}

.loginButton:hover{
    -webkit-animation: Animation 0.5s ease;
    -moz-animation: Animation 0.5s ease ;
    animation: Animation 0.5s ease;
}

@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

.demo{
    border-radius:20px;
    width:35vw;
    height:50vh;
    box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 20px 0px;
}

.links{
    width:80vw;
    height:80px;
    background-color: white;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    border-radius:100px;
    position:absolute;
    top:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color:black;
    padding:30px;
    box-sizing: border-box;
}

.navHeading{
    font-size:24px;
    font-weight: 600;
    letter-spacing: -1.33px;
    background: -webkit-linear-gradient(270deg, rgba(10,72,157,1) 0%, rgba(32,148,249,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navItem{
    font-weight:600;
    cursor:pointer;
    transition:0.3s;
    color:black;
    text-decoration: none;
}

.navItem:hover{
    color:rgba(10,72,157,1)
}

.textHighlight{
    background: -webkit-linear-gradient(180deg, rgba(10,72,157,1) 0%, rgba(32,148,249,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight:600;
}

.mobileVideo{
    display:none;
}


@media screen and (max-width: 600px) {
    .links{
        display:none;
    }
    .leftSide{
        width:100%;
        padding-left:30px;
        padding-right:30px;
    }
    .rightSide{
        width:100%;
    }
    .demo{
        width:90vw;
        margin-top:50px;
    }
  }
